<template>
  <div class="flex flex-col gap-2 sm:gap-4">
    <easiPlainTable
      :data="visibleData"
      :headers="tableHeaders"
      v-model:selected="selectedData"
    >
    
    </easiPlainTable>
  </div>

  <!-- //////  Delete Modal ////// -->
  <easiAlert
    :icon="opsIcon"
    v-if="deleteModal"
    :title="`<span class='text-xl'>Are you sure you want to delete <br/> this item?</span>`"
  >
    <div class="pb-4 px-6 grid grid-cols-2 gap-4">
      <easiButton
        variant="outlined"
        size="medium"
        @click="deleteModal = false"
        block
        >Cancel</easiButton
      >
      <easiButton
        size="medium"
        :loading="deleteLoading"
        @click="handleDelete"
        block
        >Yes</easiButton
      >
    </div>
  </easiAlert>
  <!-- //////  Alert Modal ////// -->
  <easiAlert v-if="isAlert" :caption="alertMessage">
    <div class="pb-4 px-6">
      <easiButton
        size="medium"
        :loading="loading"
        @click="isAlert = false"
        block
        >Okay!</easiButton
      >
    </div>
  </easiAlert>
</template>

<script setup>
import opsIcon from "@/assets/icons/empty-state.svg";

import { ref } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { useRouter } from "vue-router";

defineProps({
  visibleData: {
    type: Boolean,
    default: () => [],
  },
});
const { formatAmount, formatDate } = helperFunctions;
const store = useDataStore();
const router = useRouter();

const alertMessage = ref("");
const isAlert = ref(false);

const deleteLoading = ref(false);
const deleteModal = ref(false);
const selectedItem = ref(null);

const selectedData = ref([]);

const tableHeaders = [
  {
    style: ``,
    title: "FIRST NAME",
    field: "firstName",
  },
  {
    style: ``,
    title: "LAST NAME",
    field: "lastName",
  },
  {
    style: ``,
    title: "EMAIL ADDRESS",
    field: "email",
  },
  {
    style: ``,
    title: "PHONE NUMBER",
    field: "phone",
 
  },

  {
    style: ``,
    title: "COMPANY NAME",
    field: "companyName",

  },

 
];

const dropdownOptions = [
  {
    title: "View",
    action: (val) => {
      router.push({ name: "ViewClient", params: { id: val._id } });
    },
  },
  {
    title: "Edit",
    action: (val) => {
      alert("edit");
    },
  },
  {
    title: "Delete",
    action: (val) => {
      alert("delete");
    },
  },
];

const handleDelete = () => {
  deleteLoading.value = true;

  setTimeout(() => {
    deleteLoading.value = false;
    deleteModal.value = false;

    alertMessage.value = "Item successfully deleted";
    isAlert.value = true;
  }, 3000);
};
</script>

<style></style>
