<template>
  <main class="px-2 md:px-4 py-6 flex flex-col gap-4 min-h-screen">
    <h4
      class="capitalize md:uppercase md:text-sm text-xs font-medium text-headerText"
    >
      Book Keeping / Add client
    </h4>
    <!-- <easiBackButton /> -->
    <p class="font-bold text-2xl">
        Add Client
    </p>
    <div class="bg-white rounded-3xl py-10">
      <easiTab class="max-w-xl" :tabItems="tabs" v-model:active="activeTab" />

      <div>
        <AddEditClient v-if="activeTab === 'single input'" />
        <BulkAdd v-if="activeTab === 'bulk input'" />
      </div>
    </div>
  </main>
</template>

<script setup>
import BulkAdd from "@/components/BookKeeping/Client/BulkAdd.vue";
import AddEditClient from "@/components/BookKeeping/Client/AddEditClient.vue";

import { ref } from "vue";

const activeTab = ref("single input");
const tabs = ref(["single input", "bulk input"]);
</script>

<style></style>
