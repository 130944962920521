<template>
  <div>
    <div
      class="my-10 relative overflow-hidden flex mx-auto items-center justify-between gap-4 w-44"
    >
      <div
        :class="
          activeTab == 1
            ? 'bg-secondary text-white'
            : 'bg-white text-dark-700 border-dark-100'
        "
        class="w-7 h-7 text-xs rounded-full border flex items-center justify-center z-10"
      >
        1
      </div>
      <div
        :class="
          activeTab == 2
            ? 'bg-secondary text-white'
            : 'bg-white text-dark-700 border-dark-100'
        "
        class="w-7 h-7 text-xs rounded-full border flex items-center justify-center z-10"
      >
        2
      </div>
      <hr class="border-dark-100 absolute left-0 w-full" />
    </div>

    <div
      style="box-shadow: 0px 4px 25px 0px #0000000d"
      class="mx-auto w-full p-6 md:p-8 bg-white rounded-2xl overflow-hidden border border-outlined"
      :class="activeTab === 2 ? 'max-w-3xl ' : 'max-w-xl '"
    >
      <div v-if="!analyzing">
        <div v-if="activeTab === 1" class="gap-6 flex flex-col">
          <h3 class="text-center text-lg md:text-2xl font-medium">
            Upload Clients
          </h3>
          <p class="text-grey text-base">
            Download our client list template, populate it and upload to the
            system
          </p>
          <p class="text-center">Download Client List Template</p>
          <div
            class="rounded-xl w-8/12 mx-auto bg-grayLight p-4 text-sm flex items-center gap-4 justify-between"
          >
            <div class="flex items-center gap-4">
              <img class="" src="@/assets/img/xlsTemplate.svg" alt="" />

              <div class="flex flex-col gap-1">
                <p>Client List Template.xls</p>
              </div>
            </div>

            <easiButton
              @click="handleDownload"
              variant="text"
              size="small"
              class="bg-white gap-2"
            >
              <img class="" src="@/assets/img/downloadTemplate.svg" alt="" />
            </easiButton>
          </div>
          <hr />
          <div
            class="p-4 cursor-pointer border border-dashed border-dark-300 rounded-xl text-sm flex flex-col items-center gap-2"
            @click="openFileInput"
            >
            <input
              ref="fileInput"
              type="file"
              style="display: none"
              @change="handleFile"
              accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
            <span class="text-newGray"> Upload Document </span>
            <p class="text-newGray">(xls,xlsx Only) (2mb Maximum)</p>
          </div>
          <easiButton @click="handleSave" class="px-20 mx-auto"
            >Submit</easiButton
          >
        </div>
        <div v-else>
          <p class="text-grey font-medium text-2xl text-center mb-6">
            Review Upload
          </p>

          <PreviewClients :visibleData="visibleData" />
          <div class="flex justify-center mt-6">
            <easiButton
              @click="handleUpload"
              size="small"
              class="bg-white gap-2"
              :loading="loading"
            >
              Upload
            </easiButton>
          </div>
        </div>
      </div>

      <!-- ///// Analyzing ///// -->

      <div v-else>
        <div class="flex flex-col gap-2 items-center">
          <h3 class="text-center text-lg md:text-2xl font-semibold">
            Analysing Data
          </h3>

          <easiLoader class="w-48 mx-auto rounded-full" />
          <span class="text-xs">
            <strong> Hold</strong> on this won’t take long
          </span>
        </div>
      </div>
    </div>

    <easiAlert v-if="successModal" :title="`Clients successfully uploaded`">
      <div class="flex justify-center">
        <easiButton
          size="medium"
          :loading="deleteLoading"
          @click="
            successModal = false;
            $router.push('/book-keeping/client');
          "
          block
          >Proceed</easiButton
        >
      </div>
    </easiAlert>
  </div>
</template>

<script setup>
import { ref } from "vue";
import * as XLSX from "xlsx/xlsx.mjs";
import { useToast } from "vue-toastification";
import { useDataStore } from "@/stores/data.js";
const fileInput = ref(null);
const openFileInput = () => {
  fileInput.value.click();
};
const toast = useToast();
const store = useDataStore();
const { mutate } = store;
import PreviewClients from "@/components/BookKeeping/Client/PreviewClients";
const visibleData = ref([]);
const analyzing = ref(false);
const successModal = ref(false);
const loading = ref(false);
const uploadFile = ref(null);
const activeTab = ref(1);
const handleDownload = () => {
  // Create a new worksheet
  const ws = XLSX.utils.json_to_sheet([
    {
      firstName: "",
      lastName: "",
      address: "",
      phone: "",
      email: "",
      companyName: "",
      clientNote: "",
    },
  ]);

  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, "Template");

  // Convert the workbook to a binary string
  const wbBinary = XLSX.write(wb, { type: "binary", bookType: "xlsx" });

  // Convert the binary string to a Blob
  const blob = new Blob([s2ab(wbBinary)], { type: "application/octet-stream" });

  // Create a temporary anchor element
  const a = document.createElement("a");
  document.body.appendChild(a);

  // Set the href attribute of the anchor to the Blob object
  a.href = window.URL.createObjectURL(blob);

  // Set the download attribute of the anchor
  a.download = "template.xlsx";

  // Click the anchor to trigger the download
  a.click();

  // Remove the temporary anchor element
  document.body.removeChild(a);
};
function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}
function parseTemplate(template) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Define onload event handler
    reader.onload = function (event) {
      const data = event.target.result;

      // Parse the xlsx template
      const workbook = XLSX.read(data, { type: "binary" });

      // Get the first sheet
      const sheet = workbook.Sheets[workbook.SheetNames[0]];

      // Convert the sheet to JSON format
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // Extract headers from the first row
      const headers = parsedData.shift();

      // Find the index of the first empty row
      let firstEmptyRowIndex = parsedData.findIndex((row) =>
        row.every((cell) => cell === undefined || cell === "")
      );

      // If no empty row found, set index to end of data
      if (firstEmptyRowIndex === -1) {
        firstEmptyRowIndex = parsedData.length;
      }

      // Slice the data up to the first empty row
      const resultData = parsedData.slice(0, firstEmptyRowIndex);

      // Convert rows to objects with headers as keys
      const result = resultData.map((row) => {
        const obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index];
        });
        return obj;
      });

      // Resolve with the parsed data
      resolve(result);
    };

    // Define onerror event handler
    reader.onerror = function (event) {
      // Reject with the error
      reject(event.target.error);
    };

    // Read the file as binary data
    reader.readAsBinaryString(template);
  });
}
const uploadedTemplate = ref("");
const handleFile = (e) => {
  console.log("fghj",e)
  uploadedTemplate.value = e.target.files[0];
};
const handleUpload = async () => {
  try {
    loading.value = false;

    let res = await mutate({
      endpoint: "CreateBulkInvoiceClients",
      data: {
        input: visibleData.value.map((each) => ({ ...each, isSave: true })),
      },
      service: "BOOKKEEPING",
    });
    console.log("res is", res);
    if (res.success) {
      successModal.value = true;
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
};
const handleSave = async () => {
  if (!uploadedTemplate.value) return toast.error("please attach a file");

  analyzing.value = true;
  try {
    console.log("file", await parseTemplate(uploadedTemplate.value));
    visibleData.value = await parseTemplate(uploadedTemplate.value);
    setTimeout(() => {
      analyzing.value = false;
      activeTab.value = 2;
    }, 3000);
  } catch (e) {
    toast.error("error while parsing file");
  }
};
</script>

<style></style>
